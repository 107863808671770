$(function() {
  // ==================================================
  //  Slick Slider
  // ==================================================
  const js_slickSliderClass = 'js_slickSlider';
  $('.' + js_slickSliderClass).slick({
    fade: true,
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnFocus: false,
    pauseOnHover: false,
    touchMove: false,
		dots: false,
    arrows: false
	});
});